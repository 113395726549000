@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow:hover {
  transform: scale(1.5);
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.centered-image {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.scale-container {
  transform: scale(1);
  transform-origin: top left;
}

.positioned-image {
  transition: transform 0.3s ease;
}

.positioned-image:hover {
  transform: scale(1.2);
}

.positioned-text {
  position: absolute;
}

.input-field {
  position: absolute;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-top: 10px;
}

/* styles.css */
@media (max-width: 1600px) {
  .scale-container {
    overflow: hidden;
    width: 1600px;
  }
}



.phone-meme-generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

}

.top-ui-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.top-ui {
  max-width: 100%;
  height: auto;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.meme-canvas {
  position: relative;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 642 / 456;
  margin-bottom: 20px;
}

.meme-template {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dad-head {
  position: absolute;
  left: 11.68%;
  top: 33.77%;
  width: 14.80%;
  height: 17.76%;
  object-fit: cover;
}

.child-head {
  position: absolute;
  left: 60.59%;
  top: 51.32%;
  width: 9.35%;
  height: 12.94%;
  object-fit: cover;
}

.kids-background-container {
  position: absolute;
  right: 5%;
  /* Changed from left to right for easier top-right positioning */
  top: 40%;
  /* Moved higher up */
  width: 25%;
  /* Reduced width */
  height: 35%;
  /* Reduced height */
  overflow: hidden;
  transform: rotate(15deg);
}

.kids-background {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.meme-text {
  position: absolute;
  left: 62.00%;
  top: 25.66%;
  width: 38.16%;
  height: 21.49%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
}

.image-selector {
  margin-bottom: 20px;
}

.image-selector h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.image-options {
  display: flex;
  overflow-x: auto;
  gap: 10px;
}

.selectable-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
}

.selectable-image.selected {
  border-color: red;
}

.text-input input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.tweet-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  background-color: #000;
  color: white;
}

.tweet-button {
  background-color: #fff;
  color: black;
  border: 1px solid black;
}

.image-options {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
}

.selectable-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 2px solid transparent;
  flex-shrink: 0;
  background-color: #f0f0f0;
  /* Light grey background to see the bounds */
}

.background-image {
  width: 106.67px;
  /* 16:9 aspect ratio based on 60px height */
  height: 60px;
  object-fit: contain;
}

.selectable-image.selected {
  border-color: red;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  /* Adjust the spacing between the buttons */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
  /* Ensure full width */
}

.bottom-ui-container {
    position: static;
    width: 130%;
    overflow: hidden;
    z-index: 0; /* Set to 0 or even a negative value if needed */

}

.bottom-ui-wrapper {
    width: 120%;
    margin-left: -10%;
    transform: translateY(-10px); /* Move the content up by 10px */
}

.bottom-ui {
    width: 100%;
    height: auto;
    display: block;
}